/*
This is frontend library
*/
import io from "socket.io-client";
class SignzyMessagingService {
    socketURL = "/";
    path = "/sms";

    constructor(channelId, socketURL, path) {
        if (socketURL) {
            this.socketURL = socketURL;
        }
        if (path) {
            this.path = path;
        }
        this.channelId = channelId;

        this.socket = io(this.socketURL, {
            path: this.path
        });
        this.socket.on('connect', () => {
            console.log(`Socket connected id: ${this.socket.id}`);
            this.socket.emit("registerChannel", this.channelId);
        });
    }

    on(eventName, cb) {
        this.socket.on("receiveMessage", (data) => {
            if (data.sessionId == this.channelId) {
                if (data.data.eventName == eventName) {
                    cb(data.data.data);
                }
            }
        })
    }

    emit(eventName, data) {
        this.socket.emit("pushMessage", {
            sessionId: this.channelId,
            data: {
                eventName: eventName,
                data: data
            }
        });
    }

    end() {
        this.socket.removeAllListeners();
        this.socket.disconnect();
        this.socket.close();
    }
}

export {
    SignzyMessagingService
}