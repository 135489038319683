import {
  ConsoleLogger, DefaultDeviceController, DefaultMeetingSession, LogLevel, MeetingSessionConfiguration,DefaultBrowserBehavior
} from 'amazon-chime-sdk-js';
import axios from 'axios';

class SignzyChimeVideoService {
  meetingState = { isActive: false };

  constructor(meeting_id, meeting_url, name, userIdentifier, localVideo, remoteVideo, contentVideo, audioElement, callback) {
      this.meeting_id = meeting_id;
      this.name = name;
      this.userIdentifier = userIdentifier;
      this.meeting_url = meeting_url;
      this.localVideoElement = localVideo;
      this.remoteVideoElement = remoteVideo;
      this.contentVideoElement = contentVideo;
      this.audioElement = audioElement;
      this.currentAttendee = undefined;
      this.speakerTiles = [];
      this.contentTiles = [];
      this.meetingRoster= {};
      this.existingLocalTileId = null;
      this.existingRemoteTileId = null;
      this.existingContentTileId = null;
      this.callback = callback;
      this.defaultBrowserBehaviour=new DefaultBrowserBehavior();
  }

  setMeetingState = (partialState) => {
      this.meetingState = { ...this.meetingState, ...partialState };
  }

  initialise = async () => {
      try {
          const { data: { data } } = await axios.post(`${this.meeting_url}/start`, { id: this.userIdentifier, name: this.name });
          const logger = new ConsoleLogger('MeetingLogger', LogLevel.ERROR);
          const deviceController = new DefaultDeviceController(logger);
          const configuration = new MeetingSessionConfiguration(data.meeting.meeting_info, { Attendee: data.current_attendee });
          this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);
          this.setMeetingState({ isActive: true });
          this.currentAttendee = data.current_attendee;
          console.log('this currentAttendee', this.currentAttendee);
          return data.current_attendee;
        } catch (err) {
          console.error('Failed to initialise meeting service');
          throw new Error(err);
        }
  }

  start = () => {
    const observers = this.createMeetingObservers();
    this.setAudioOutput();
    this.meetingSession.audioVideo.addObserver(observers);
    this.meetingSession.audioVideo.start();
    this.meetingSession.audioVideo.startLocalVideoTile();
  }

  getVideoInputDevices = () => {
    return this.meetingSession.audioVideo.listVideoInputDevices();
  }

  setVideoInputDevice = async (deviceId) => {
    this.lastSelectedVideoDeviceId = deviceId || this.lastSelectedVideoDeviceId;
    await this.meetingSession.audioVideo.chooseVideoInputDevice(this.lastSelectedVideoDeviceId);
  }

  stopLocalVideo = async () => {
    await this.meetingSession.audioVideo.stopLocalVideoTile();
  }

  getAvailableMicrophones = () => {
    return this.meetingSession.audioVideo.listAudioInputDevices();
  }

  setMicrophone = async (deviceId) => {
    this.micStream = await navigator.mediaDevices.getUserMedia({
      audio: { deviceId: deviceId }
    });
    await this.meetingSession.audioVideo.chooseAudioInputDevice(deviceId); //extra
  }

  isMuted =  ()  => {
    return this.meetingSession.audioVideo.realtimeIsLocalAudioMuted();
  }

  muteTrack = (trackType) => {
    if(trackType == "audio") {
      this.meetingSession.audioVideo.realtimeMuteLocalAudio();
      this.toggleAudioTracksInStream(this.micStream,true);
    }
    else if(trackType == "video")
      this.meetingSession.audioVideo.stopLocalVideoTile();
  }

  unMuteTrack = (trackType) => {
    if(trackType == "audio") {
      this.meetingSession.audioVideo.realtimeUnmuteLocalAudio();
      this.toggleAudioTracksInStream(this.micStream,false);
    }
    else if(trackType == "video")
      this.meetingSession.audioVideo.startLocalVideoTile();
  }

  toggleAudioTracksInStream(mediaStream, mute) {
    try {
      if(mediaStream) {
        let audioTracks = mediaStream.getAudioTracks();
        for(let i = 0 ; i < audioTracks.length ; i++) {
          audioTracks[i].enabled = mute ? false : true;
        }
      } else {
        console.log("mic stream not availbale - unmute track function");
      }
    } catch(err) {
      console.log("error toggle audio stream::", err);
    }
  }

  getAvailableSpeakers = () => {
    return this.meetingSession.audioVideo.listAudioOutputDevices();
  }

  setSpeaker = async (deviceId) => {
    if (this.defaultBrowserBehaviour?.supportsSetSinkId()) {
      try {
        await this.meetingSession.audioVideo.chooseAudioOutputDevice(deviceId);
      } catch (e) {
        console.log("failed to chooseAudioOutputDevice", e);
      }
    }
  }

  setAudioOutput = () => {
    this.meetingSession.audioVideo.bindAudioElement(this.audioElement);
  }

  setVideoOutput = (tileId, videoElement) => {
    this.meetingSession.audioVideo.bindVideoElement(tileId, videoElement);
  }

  startScreenShare = async () => {
    await this.meetingSession.audioVideo.startContentShareFromScreenCapture();
  }

  switchCamera = async (frontCamera) => {
    try{
      const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();
      //switch to back
      if(frontCamera){
        for(let i = 0 ; i < videoInputDevices.length ; i++) {
          if(videoInputDevices[i].label && videoInputDevices[i].label.toLowerCase().includes('back')) {
            await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[i].deviceId);
            break;
          }
        }
      } else {
        for(let i = 0 ; i < videoInputDevices.length ; i++) {
          if(videoInputDevices[i].label.toLowerCase().includes('front')) {
            await this.meetingSession.audioVideo.chooseVideoInputDevice(videoInputDevices[i].deviceId);
            break;
          }
        }
      }
    }catch(err){
      console.log("unable to toggle"+err.name + ": " + err.message);
    }
  }

  stopScreenShare = async () => {
    await this.meetingSession.audioVideo.stopContentShare();
  }

  startRecording = async () => {
    await axios.post(`${this.meeting_url}/recordings`, { action: 'start' });
  }

  isRecording = async () => {
    const { data: response } = await axios.get(`${this.meeting_url}/recordings/status`);
    return response.data.started;
  }

  stopRecording = async () => {
    await axios.post(`${this.meeting_url}/recordings`, { action: 'stop' });
  }

  localTile = () => {
    const tile =
      this.speakerTiles.find(
        (speakerTile) => speakerTile.boundAttendeeId === this.currentAttendee.AttendeeId
      );

    return tile;
  }

  remoteTile = () => {
    const speakerTileId = this.speakerTiles.filter(tile => !tile.localTile).reduce((acc, tile) => acc > tile.tileId ? acc : tile.tileId, 0);



    return this.speakerTiles.find(tile => tile.tileId === speakerTileId);
  }

  contentTile = () => {
    const tile = this.contentTiles.find((speakerTile) => speakerTile.boundAttendeeId === `${this.currentAttendee.AttendeeId}#content`);
    return tile;
  }

  remoteContentTile = () => {
    const remoteContentTileId = this.contentTiles.filter((tile) =>
      tile.boundAttendeeId !== `${this.currentAttendee.AttendeeId}#content`
    ).reduce((acc, tile) => acc > tile.tileId ? acc : tile.tileId, 0);

    return this.contentTiles.find(tile => tile.tileId === remoteContentTileId);
  }

  bindLocalVideoElement = () => {
    try {
      const localTile = this.localTile();
      this.localMediaStream = localTile?.boundVideoStream;
      if (!localTile) return;
      const tileId = localTile.tileId;
      const videoElement = this.localVideoElement;
      if (tileId === this.existingLocalTileId) return;
      console.log('speaker tile update- local tile', this.existingLocalTileId, localTile);
      this.existingLocalTileId = tileId;
      this.meetingSession.audioVideo.bindVideoElement(
        tileId,
        videoElement
      );
    } catch (e) {
      console.error("TileState - Something went wrong");
    }
  }

   bindRemoteVideoElement = () => {
    try {
      const remoteTile = this.remoteTile();
      this.remoteMediaStream = remoteTile?.boundVideoStream;
      if (!remoteTile) return;
      const tileId = remoteTile.tileId;
      const videoElement = this.remoteVideoElement;
      if (tileId === this.existingRemoteTileId) return;
      console.log('speaker tile update- remote tile', this.existingRemoteTileId, remoteTile);
      this.existingRemoteTileId = tileId;
      this.meetingSession.audioVideo.bindVideoElement(
        tileId,
        videoElement
      );
    } catch (e) {
      console.error("TileState - Something went wrong", e);
    }
  }

  bindContentVideoElement = () => {
    try {
      const contentTile = this.contentTile();
      if (!contentTile) return;
      const tileId = contentTile.tileId;
      const videoElement = this.contentVideoElement;
      if (tileId === this.existingContentTileId) return;
      console.log('binding content tile', this.existingContentTileId, contentTile);

      this.existingContentTileId = tileId;
      this.meetingSession.audioVideo.bindVideoElement(
        tileId,
        videoElement
      );
    } catch (e) {
      console.error("TileState - Something went wrong");
    }
  }

  bindRemoteContentVideoElement = () => {
    try {
      const remoteContentTile = this.remoteContentTile();
      if (!remoteContentTile) return;
      const tileId = remoteContentTile.tileId;
      const videoElement = this.remoteContentElement;
      if (tileId === this.existingRemoteContentTileId) return;
      this.existingRemoteContentTileId = tileId;
      this.meetingSession.audioVideo.bindVideoElement(
        tileId,
        videoElement
      );
    } catch (error) {
      console.error("TileState - Something went wrong");
    }
  }


  handleContentTileDidUpdate = (tileState) => {
    const indexOfTileId = this.contentTiles.findIndex((tile) => tile.tileId === tileState.tileId);
    if (indexOfTileId === -1) this.contentTiles = [...this.contentTiles, tileState];
    else this.contentTiles = [...this.contentTiles.slice(0, indexOfTileId), tileState, ...this.contentTiles.slice(indexOfTileId + 1)];

    this.bindContentVideoElement();
    this.bindRemoteContentVideoElement();
  }

  handleSpeakerTileDidUpdate = (tileState) => {
    const indexOfTileId = this.speakerTiles.findIndex((tile) => tile.tileId === tileState.tileId);
    if (indexOfTileId === -1) this.speakerTiles = [...this.speakerTiles, tileState];
    else this.speakerTiles = [...this.speakerTiles.slice(0, indexOfTileId), tileState, ...this.speakerTiles.slice(indexOfTileId + 1)];

    this.bindLocalVideoElement();
    this.bindRemoteVideoElement();
    this.workOnArrayStream(this.callback);
  }

  workOnArrayStream = (callback) => {
    try {
      if(callback) {
        let localMediaStream = this.localMediaStream;//(this.localVideoElement).captureStream();
        let remoteMediaStream = this.remoteMediaStream;//(this.remoteVideoElement).captureStream();
        let remoteAudioMediaStream = (this.audioElement)?.captureStream();
        let localAudioMediaStream = this.micStream;
        let arrayOfStream = [];
        if(localMediaStream) {
          arrayOfStream.push(localMediaStream);
        }
        if(remoteMediaStream) {
          arrayOfStream.push(remoteMediaStream);
        }
        if(remoteAudioMediaStream) {
          arrayOfStream.push(remoteAudioMediaStream);
        }
        if(localAudioMediaStream) {
          arrayOfStream.push(localAudioMediaStream);
        }
        if (arrayOfStream && arrayOfStream.length == 4) {
            if (callback) {
              callback(arrayOfStream);
            }
        } else {
            console.warn("[!] Failed in pulling 4 streams, trying in 3000 ms!")
            if (callback) {
              callback(arrayOfStream);
            }
            setTimeout(() => {
                this.workOnArrayStream(callback)
            }, 3000);
        }
      }
    } catch(err) {
      console.error(err);
    }
  }


  createMeetingObservers = () => {
    let self = this;
    return ({
      videoTileDidUpdate: (tileState) => {
        const { boundAttendeeId, isContent } = tileState;
        if (!boundAttendeeId) return null;
        if (isContent) return self.handleContentTileDidUpdate(tileState);
        return self.handleSpeakerTileDidUpdate(tileState);
      },

      videoTileWasRemoved: (tileId) => {
        const { speakerTiles, contentTiles } = self;
        const indexOfSpeakerTileId = speakerTiles.findIndex((tile) => tile.tileId === tileId);
        if (indexOfSpeakerTileId !== -1) {
          self.speakerTiles = [...speakerTiles.slice(0, indexOfSpeakerTileId), ...speakerTiles.slice(indexOfSpeakerTileId + 1)];
          return;
        }
        const indexOfContentTileId = contentTiles.findIndex((tile) => tile.tileId === tileId);
        if (indexOfContentTileId === -1) return;
        self.contentTiles = [...contentTiles.slice(0, indexOfContentTileId), ...contentTiles.slice(indexOfContentTileId + 1)];
      },
    });
  }

  leaveRoom = async () => {
    try {
      this.meetingSession.audioVideo.stop();
    } catch (error) {
        console.log("Error at leaveRoom", error);
    }
  }
}

export default SignzyChimeVideoService;
